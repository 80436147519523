import React from 'react'
import styled from 'styled-components'

import {Container} from 'reactstrap'
import Background from '../../../assets/img/background/homepage_top_section.png'
import Button from '../../../components/Button'

interface HomePageSection1Props {}

const HomePageSection1: React.FC<HomePageSection1Props> = () => {
  return (
    <Container className="themed-container top-section" fluid="xl" style={{
      background: `url(${Background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      height: 523
    }}>
      <Container className="themed-container" fluid="lg">
        <StyledWrap className="top-banner">
          <StyledTitle className="heading">IN PUBLISHING AND GRAPHIC DESIGN</StyledTitle>
          <StyledSubtitle>Lorem ipsum is a placeholder text commonly used to demonstrate</StyledSubtitle>

          <StyledWrapButton>
            <Button text='Product Introduce' size='lg' />
            <Button text='User Guide' size='lg' bgColor='blue' />
          </StyledWrapButton>
        </StyledWrap>
        
      </Container>
    </Container>
  )
}

const StyledWrap = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => props.theme.spacing[6]}px;
  padding-top: 105px;
  margin: 0 auto;
`

const StyledWrapButton = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding-bottom: ${(props) => props.theme.spacing[6]}px;
  padding-top: 78px;
  margin: 0 auto;
  width: 36%;
  justify-content: space-around;
  
  > button {
    width: auto;
  }
`

const StyledTitle = styled.h1`
  color: ${(props) => props.theme.color.white};
  font-size: 55px;
  line-height: 60px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  --stroke-color: black;
  --stroke-width: 4px;
  color: white;
  text-shadow: var(--stroke-width) 0 0 var(--stroke-color), calc(var(--stroke-width) * -1) 0 0 var(--stroke-color), 0 var(--stroke-width) 0 var(--stroke-color), 0 calc(var(--stroke-width) * -1) 0 var(--stroke-color);
`

const StyledSubtitle = styled.h3`
  color: ${(props) => props.theme.color.white};
  font-size: 27px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
`

export default HomePageSection1
