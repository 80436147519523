import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init
import translationEN from './locales/en/translation.json';
import translationZH from './locales/zh/translation.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    zh: {
        translation: translationZH
    },
};

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng:'zh', // default
        whitelist: ['en', 'zh'],
        fallbackLng: 'en',
        debug: true,

        resources,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        detection: {
            order: ['path'],
            // checkWhitelist: true,
        }
    }, () => {

    });

function setLang() {
    let urlLanguage = 'zh';
    if (window.location.pathname.indexOf('/en') !== -1) {
        urlLanguage = 'en';
    }
    if (urlLanguage !== i18n.language) {
        i18n.changeLanguage(urlLanguage);
    }
}

setLang()
window.onpopstate = setLang

export default i18n;