import BigNumber from 'bignumber.js'
import {useCallback, useEffect, useRef, useState} from 'react';

export {default as formatAddress} from './formatAddress'

export const bnToDec = (bn: BigNumber, decimals = 18): number => {
    return bn.dividedBy(new BigNumber(10).pow(decimals)).toNumber()
}

export const decToBn = (dec: number, decimals = 18) => {
    return new BigNumber(dec).multipliedBy(new BigNumber(10).pow(decimals))
}

export const getAvatarUrl = (card: any) => {
    return `https://legend-shot.github.io/avatar/avatar_${card.quality}_${card.heroTypeId}_${card.prop}.png`
}


export const generateLink = (i18n: any, link: string) => {
    let baseUrl = i18n.language === i18n.options.fallbackLng[0] ? '/' : `/${i18n.language}`;

    if (link === '/') {
        return `${baseUrl}`;
    }

    if (baseUrl === "/") {
        baseUrl = "";
    }

    return link.startsWith('/') ? `${baseUrl}${link}` : `${baseUrl}/${link}`;
}

export function useFetchState(props?: any) {
    const focus: any = useRef();
    const [state, setState] = useState(props);
    useEffect(() => {
        focus.current = true;
        return () => (focus.current = false);
    }, []);
    const setFetchState = useCallback((params) => {
        focus.current && setState(params);
    }, []);
    return [state, setFetchState];
}