import React, {useCallback} from 'react'
import Button from '../../../components/Button'
import './CardBox.css'
import {useWallet} from "use-wallet";
import {Contract} from "web3-eth-contract";
import {heroPool} from "../../../sushi/utils";
import {useFetchState} from "../../../utils";
import BigNumber from "bignumber.js/bignumber";

interface CardBoxProps {
    heroPoolContract: Contract
    stakedCards: any[]
    totalFight: number
    myFight: number
    earned: number
    getEarned: () => void
}

const CardBox: React.FC<CardBoxProps> = (
    {
        heroPoolContract,
        stakedCards,
        totalFight,
        myFight,
        earned,
        getEarned
    }
) => {
    let earnedStr = new BigNumber(earned).div(Math.pow(10, 18)).toString()
    const {account} = useWallet()
    const [withdrawLoading, setWithdrawLoading] = useFetchState(false)
    const [withdrawErrorMsg, setWithdrawErrorMsg] = useFetchState('')

    const withdrawEarned = useCallback(() => {
        if (heroPoolContract && account) {
            setWithdrawLoading(true)
            heroPool.getReward(heroPoolContract, account).then(() => {
                getEarned()
            }).catch(e => {
                //4001 User denied transaction signature
                if (e.code !== 4001) {
                    setWithdrawErrorMsg(e.message)
                }
            }).finally(() => {
                setWithdrawLoading(false)
            })
        }
    }, [heroPoolContract, account])
    return (
        <div className="card__box">
            <div className="card-box-1">
                <p>LGD</p>
                <p>Awards Pool</p>
            </div>
            <div className="card-box-2">
                <p>Staked Cards: {stakedCards.length}</p>
                <p>Total Fight: {totalFight}</p>
                <p>My Fight: {myFight}</p>
            </div>
            <div className="card-box-3">
                <p>{earnedStr}</p>
                <p>Earned LGD</p>
                {withdrawErrorMsg && <p className="errorMsg">{withdrawErrorMsg}</p>}
                <Button
                    text={withdrawLoading ? "Harvest..." : "Harvest"}
                    size="sm"
                    disabled={withdrawLoading || earned === 0}
                    account={account}
                    bgColor="blue"
                    onClick={withdrawEarned}
                />
            </div>
        </div>
    )
}

export default CardBox
