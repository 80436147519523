import React from 'react'
import Button from '../../../components/Button'
import './ListCard.css'
import {generateLink} from "../../../utils";
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";

interface FilterCardProps {
    onChange?: (e: any) => void
    onClick?: () => void
    onKeyDown?: (e:any) => void
    value?: string
}

const FilterCard: React.FC<FilterCardProps> = ({onChange, onClick, onKeyDown, value}) => {
    const history = useHistory()
    const {i18n} = useTranslation()

    return (
        <div className="filter-card">
            <div className="groups-filterr">
                <div className="left">
                    <div className="bg-game">
                        <select name="" id="">
                            <option value="">All Cards</option>
                        </select>
                    </div>
                    <div className="input-search">
                        <div className="bg-black">
                            <input type="text" placeholder="Search number of card" value={value}
                                   onKeyDown={onKeyDown}
                                   onChange={onChange}/>
                        </div>
                        <Button text="Search" onClick={onClick} size="sm" bgColor="blue"/>
                    </div>
                </div>
                <div className="right">
                    <Button onClick={() => {
                        history.push(generateLink(i18n, '/cardshop'))
                    }} text="Buy Card" size="sm"/>
                </div>
            </div>
        </div>
    )
}

export default FilterCard
