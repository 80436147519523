import React, {useCallback, useEffect} from 'react'
import './components/Pool.css'
import CardBox from './components/CardBox'
import ListEquip from './components/ListEquip'
import FooterSection from '../Home/components/FooterSection'
import useSushi from "../../hooks/useSushi";
import {heroPool, heroArmory} from "../../sushi/utils";
import {useWallet} from "use-wallet";
import BigNumber from "bignumber.js/bignumber";
import {getAvatarUrl, useFetchState} from "../../utils";
import useBlock from "../../hooks/useBlock";

const Pool: React.FC = () => {
    const block = useBlock()
    const sushi: any = useSushi()
    const {account} = useWallet()
    const [web3, setWeb3] = useFetchState(null)
    const [heroContract, setHeroContract] = useFetchState(null)
    const [lgdHeroContract, setLgdHeroContract] = useFetchState(null)
    const [heroPoolContract, setHeroPoolContract] = useFetchState(null)
    const [heroArmoryContract, setHeroArmoryContract] = useFetchState(null)
    const [totalFight, setTotalFight] = useFetchState(0)
    const [myFight, setMyFight] = useFetchState(0)
    const [earned, setEarned] = useFetchState(0)
    useEffect(() => {
        if (sushi) {
            setHeroPoolContract(sushi.contracts.heroPool)
            setHeroContract(sushi.contracts.heroArmory)
            setLgdHeroContract(sushi.contracts.lgdHero)
            setHeroArmoryContract(sushi.contracts.heroArmory)
            setWeb3(sushi.web3)
        }
    }, [sushi])


    const [stakedCards, setStakedCards] = useFetchState([])

    const getStakedCards = useCallback(() => {
        if (heroArmoryContract) {
            heroPool.stakeOnwer(heroPoolContract, account).then(res => {
                let all = res.map((item: string) => {
                    return heroArmory.getHeroById(heroArmoryContract, item, account).then(res => {
                        return res
                    })
                })
                Promise.all(all).then(ls => {
                    let subList = ls.map((sub: any) => {
                        return {
                            fight: sub.fight,
                            name: sub.name,
                            prop: sub.prop,
                            quality: sub.quality,
                            tokenId: sub.tokenId,
                            imgUrl:  getAvatarUrl(sub),
                        }
                    })
                    setStakedCards(subList)
                })
            })
        }
    }, [heroPoolContract, heroArmoryContract, account, block])

    const getMyFight = useCallback(() => {
        if (heroPoolContract && account && stakedCards.length) {
            heroPool.balanceOfFight(heroPoolContract, account).then(res => {
                setMyFight(new BigNumber(res).toNumber())
            })
        }
    }, [heroPoolContract, account, stakedCards, block])

    const getTotalFight = useCallback(() => {
        if (heroPoolContract && account) {
            heroPool.totalFight(heroPoolContract, account).then(res => {
                setTotalFight(new BigNumber(res).toNumber())
            })
        }
    }, [heroPoolContract, account, block])
    const getEarned = useCallback(() => {
        if (heroPoolContract && account) {
            heroPool.earned(heroPoolContract, account).then(res => {
                setEarned(new BigNumber(res).toNumber())
            })
        }
    }, [heroPoolContract, account, block])

    useEffect(() => {
        getTotalFight()
        getEarned()
    }, [heroPoolContract, account, block])

    useEffect(() => {
        getMyFight()
    }, [heroPoolContract, heroArmoryContract, stakedCards, account])

    useEffect(() => {
        getStakedCards()
    }, [heroPoolContract, heroArmoryContract, account, block])

    return (
        <div className="pool">
            <div className="top">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>Rewards Pool</h1>
                            <p>Equip your cards and get awards</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <CardBox
                    heroPoolContract={heroPoolContract}
                    stakedCards={stakedCards}
                    totalFight={totalFight}
                    myFight={myFight}
                    earned={earned}
                    getEarned={getEarned}
                />
                <ListEquip
                    web3={web3}
                    lgdHeroContract={lgdHeroContract}
                    heroContract={heroContract}
                    heroPoolContract={heroPoolContract}
                    stakedCards={stakedCards}
                    changeStakedCards={getStakedCards}
                />
            </div>
            <div id="footer">
                <FooterSection/>
            </div>
        </div>
    )
}

export default Pool
