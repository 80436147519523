import React from 'react'
import SlideCard from './components/SlideCard'
import FooterSection from '../Home/components/FooterSection'

const Card: React.FC = () => {
  return (
    <div>
      <SlideCard />
      <div id="footer">
        <FooterSection />
      </div>
    </div>
  )
}

export default Card
