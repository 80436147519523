import React, {useCallback, useEffect, useState} from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {ThemeProvider} from 'styled-components'
import {UseWalletProvider} from 'use-wallet'
import DisclaimerModal from './components/DisclaimerModal'
import MobileMenu from './components/MobileMenu'
import TopBar from './components/TopBar'
import FarmsProvider from './contexts/Farms'
import ModalsProvider from './contexts/Modals'
import TransactionProvider from './contexts/Transactions'
import SushiProvider from './contexts/SushiProvider'
import useModal from './hooks/useModal'
import theme from './theme'
import Farms from './views/Farms'
import Home from './views/Home'
import Stake from './views/Stake'
import Card from './views/Cards'
import Pool from './views/Pool'

const App: React.FC = () => {
    const [mobileMenu, setMobileMenu] = useState(false)

    const handleDismissMobileMenu = useCallback(() => {
        setMobileMenu(false)
    }, [setMobileMenu])

    const handlePresentMobileMenu = useCallback(() => {
        setMobileMenu(true)
    }, [setMobileMenu])

    const localesString = "/:lng(en|zh|vi)?"

    return (
        <Providers>
            <Router>
                <TopBar onPresentMobileMenu={handlePresentMobileMenu}/>
                <MobileMenu onDismiss={handleDismissMobileMenu} visible={mobileMenu}/>
                <Switch>
                    <Route path={`${localesString}/`} exact>
                        <Home/>
                    </Route>
                    <Route path={`${localesString}/farms`}>
                        <Farms/>
                    </Route>
                    <Route path={`${localesString}/staking`}>
                        <Stake/>
                    </Route>
                    <Route path={`${localesString}/cardshop`}>
                        <Card/>
                    </Route>
                    <Route path={`${localesString}/cardshop/mycards`}>
                        <Card/>
                    </Route>
                    <Route path={`${localesString}/pool`}>
                        <Pool/>
                    </Route>
                </Switch>
            </Router>
            <Disclaimer/>
        </Providers>
    )
}

const Providers: React.FC = ({children}) => {
    return (
        <ThemeProvider theme={theme}>
            <UseWalletProvider
                chainId={4}
                connectors={{
                    walletconnect: {rpcUrl: 'https://rinkeby.infura.io/v3/undefined'},
                    // walletconnect: {rpcUrl: 'https://mainnet.eth.aragon.network/'},
                }}
            >
                <SushiProvider>
                    <TransactionProvider>
                        <FarmsProvider>
                            <ModalsProvider>{children}</ModalsProvider>
                        </FarmsProvider>
                    </TransactionProvider>
                </SushiProvider>
            </UseWalletProvider>
        </ThemeProvider>
    )
}

const Disclaimer: React.FC = () => {
    const markSeen = useCallback(() => {
        localStorage.setItem('disclaimer', 'seen')
    }, [])

    const [onPresentDisclaimerModal] = useModal(
        <DisclaimerModal onConfirm={markSeen}/>,
    )

    useEffect(() => {
        const seenDisclaimer = true // localStorage.getItem('disclaimer')
        if (!seenDisclaimer) {
            onPresentDisclaimerModal()
        }
    }, [])

    return <div/>
}

export default App
