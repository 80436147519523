export const CardQuality: any = {
    0: {
        name: '白色',
        fight: 0, // 战斗力加成
        rate: 98.1 // 卡片出现概率
    },
    1: {
        name: '银色',
        fight: 5,
        rate: 1
    },
    2: {
        name: '金色',
        fight: 15,
        rate: 0.5
    },
    3: {
        name: '钻石',
        fight: 20,
        rate: 0.3
    },
    4: {
        name: 'Legend', // 传说
        fight: 25,
        rate: 0.1
    },
}