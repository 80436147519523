import React from 'react'

import calabashNormalHero from '../../../assets/img/card/calabashNormalHero.png'
import Button from '../../../components/Button'
import styled from "styled-components";

interface EquipItemProps {
    card: any
    onClick: () => void
}

const EquipItem: React.FC<EquipItemProps> = ({card, onClick}) => {
    return (
        <div className={'item ' + (card.removing ? 'removing' : '')}>
            {card && !card.key ? <img src={card.imgUrl} alt="" onClick={onClick}/> :
                <BorderImg onClick={onClick}/>
            }
            {card && !card.key ?
                <Button text="Remove" size="sm" bgColor="orange" onClick={onClick}
                        disabled={card.disabled || card.removing}/> :
                <Button text="Equip" size="sm" bgColor="blue" onClick={onClick}/>
            }
        </div>
    )
}

const BorderImg = styled.div`
  width: 180px;
  height: 240px;
  object-fit: contain;
  box-sizing: border-box;
  border: 8px dashed #ffa115;
  background-clip: padding-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 auto 15px auto;

  &:hover {
    border-color: #c25a3e;
  }

  &:after {
    content: '+';
    font-weight: 800;
    font-size: 30px;
    color: #ffa115;
  }
`;
export default EquipItem
