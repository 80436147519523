import React from 'react'

import ironmanblack from '../../../assets/img/card/ironman-black.png'

interface CardItemProps {
    handleClickConfirm?: (e: any) => void
    card: any
    activeClass: string
}

const CardItemConfirm: React.FC<CardItemProps> = ({handleClickConfirm, card, activeClass}) => {
    return (
        <div className="card-item-confirm" onClick={(e) => handleClickConfirm(e)}>
            <div className={'group-info '+activeClass}>
                <img src={card.imgUrl}/>
                <div className="information">
                    <p>Name: {card.name}</p>
                    <p>战斗力加成: {card.fightRate}%</p>
                    <p>卡片出现概率: {card.cardShowRate}%</p>
                    <p>Fight: {card.fight}</p>
                </div>
            </div>
        </div>
    )
}

export default CardItemConfirm
