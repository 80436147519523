import React, {useEffect} from 'react'
import styled from 'styled-components'
import brandIcon from '../../../assets/img/logo/brand.png'
import styles from './nav.module.css'
import Button from '../../../components/Button'
import './nav.module.css'
import {Collapse, Nav, Navbar, NavbarToggler, NavItem} from 'reactstrap';
import {NavLink, useLocation} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import {generateLink, useFetchState} from "../../../utils";
import {useWallet} from "use-wallet";
import {Modal} from "react-bootstrap";

const NavBar: React.FC = () => {
    const wallet = useWallet()
    const account = wallet.account
    useEffect(() => {
        if (wallet.status == 'disconnected') {
            wallet.connect('injected').then()
        }
    }, [wallet])
    const {t, i18n} = useTranslation()
    const {pathname, search} = useLocation()
    const langName: any = {
        en: 'English',
        zh: '中文',
    }
    let langNameKey: any = {
        en: "zh",
        zh: "en",
    }

    let [openLanguage, setOpenLanguage] = useFetchState(false)
    let [showBalance, setShowBalance] = useFetchState(false)
    const [isOpen, setIsOpen] = useFetchState(false);

    const toggle = () => setIsOpen(!isOpen);
    const toggleLang = (e: any) => {
        if (!e.target.classList.contains('lang-btn')) {
            setOpenLanguage(false)
        }
    }
    window.document.addEventListener('click', toggleLang, false)
    useEffect(() => {
        return () => {
            window.document.removeEventListener('click', toggleLang)
            setOpenLanguage(false)
        }
    }, [pathname])

    const changeLanguage = (lng: string) => {
        const oldLanguage = i18n.language;
        if (oldLanguage === lng) {
            return;
        }

        i18n.changeLanguage(lng, function () {
            if (Array.isArray(i18n.options.fallbackLng)) {
                let pathName = pathname === '/' ? '' : pathname
                pathName = pathName.replace(new RegExp(`\\/(${Object.keys(langName).join('|')})`, 'i'), '')
                let url = '/' + lng + pathName

                window.history.pushState({}, '', url)
            }
        })
    };

    return (
        <Navbar expand="lg">
            <StyledLink to={{pathname: generateLink(i18n, '/'), search}}>
                <img src={brandIcon} height="58" width='108' style={{marginTop: -4}} alt="legend"/>
            </StyledLink>

            <NavbarToggler onClick={toggle} className="mr-2" style={{background: "#fff"}}/>
            <Collapse isOpen={isOpen} navbar>
                <Nav className="w-100 mr-auto nav-bar justify-content-evenly" navbar>
                    <NavItem>
                        <StyledLink className={styles.navLink}
                                    to={{
                                        pathname: generateLink(i18n, '/cardshop'),
                                        search
                                    }}>{t('menu.card')}</StyledLink>
                    </NavItem>
                    <NavItem>
                        <StyledLink className={styles.navLink} exact
                                    to={{pathname: generateLink(i18n, '/pool'), search}}>{t('menu.pool')}</StyledLink>
                    </NavItem>
                </Nav>
            </Collapse>
            <div>
                <Button
                    account={account}
                    onClick={() => setShowBalance(true)}
                    text={account ? t('menu.MyAccount') : t('menu.UnlockAccount')}
                    size='sm'
                />
                <Button
                    account={account}
                    text={t('menu.Heco')}
                    size='sm'
                    bgColor='blue'
                />
                <LanguageButton
                    className='lang-btn'
                    size='sm'
                    bgColor='blue'
                    onClick={() => {
                        setOpenLanguage(!openLanguage)
                    }}>
                    {
                        t('common.SwitchLang', {
                            lang: langName[langNameKey[i18n.language]]
                        })
                    }
                </LanguageButton>

                <LanguageList
                    className={openLanguage ? 'showLang' : ''}
                >
                    {Object.keys(langName).map(item => <LangItem
                        size='sm'
                        bgColor='blue'
                        className={item === i18n.language ? 'active' : ''}
                        key={item}
                        onClick={() => {
                            changeLanguage(item)
                        }}
                    >
                        {langName[item]}
                    </LangItem>)}
                </LanguageList>
            </div>
            <Modal show={showBalance} onHide={() => setShowBalance(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <p>Current Account : {account}</p>
                </Modal.Body>
            </Modal>
        </Navbar>
    )
}
const LanguageList = styled.div`
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 1rem;
  display: none;
  box-sizing: border-box;
  flex-direction: column;

  &.showLang {
    display: flex;
  }
`

const LanguageButton = styled(Button)`
  user-select: none;
  position: relative;
  width: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 134px;

    // &:hover ${LanguageList}{
  //   display: flex;
  // }
`

const LangItem = styled(Button)`
  width: 160px;

  &.active,
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

const StyledLink = styled(NavLink)`
  &.active {
    color: #FF9E00;
  }
`
export default NavBar
