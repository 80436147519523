import React from 'react'
import styled from 'styled-components'

import {Container} from 'reactstrap'
import Background from '../../../assets/img/background/homepage_footer_section.png'
import logoBrand from '../../../assets/img/logo/brand.png'
import twitter from '../../../assets/img/logo/twitter.png'
import mlogo from '../../../assets/img/logo/mlogo.png'
import telegram from '../../../assets/img/logo/telegram.png'
import github from '../../../assets/img/logo/github.png'
import Button from '../../../components/Button'

interface HomePageSection1Props {}

const HomePageSection1: React.FC<HomePageSection1Props> = () => {
  return (
    <Container
      className="themed-container footer"
      fluid="xl"
      style={{
        background: `url(${Background})`,
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        marginTop: 0
      }}
    >
      <Container className="themed-container" fluid="lg">
        <StyledWrap className="footer-container">
          <StyledItem1 className="footer-column">
            <a href="https://t.me/LegendShotGroup">
            <StyledLogo className="footer-logo">
              <img src={logoBrand} style={{ width: '100%', height: 'auto' }} />
            </StyledLogo>
            </a>
            <div className="groups-social">
              <a href="#">
                <img src={twitter} style={{ width: '100%', height: 'auto' }} />
              </a>
              <a href="#">
                <img src={telegram} style={{ width: '100%', height: 'auto' }} />
              </a>
              <a href="#">
                <img src={mlogo} style={{ width: '100%', height: 'auto' }} />
              </a>
              <a href="#">
                <img src={github} style={{ width: '100%', height: 'auto' }} />
              </a>
            </div>
          </StyledItem1>
          <StyledItem2 className="footer-column">
            <StyledCategoryText>Guide</StyledCategoryText>
            <StyledSubCategoryText>Gitbook (English)</StyledSubCategoryText>
            <StyledSubCategoryText>Gitbook (Chinese)</StyledSubCategoryText>
            <StyledSubCategoryText>Yuque (Chinese)</StyledSubCategoryText>
          </StyledItem2>
          <StyledItem3 className="footer-column">
            <StyledCategoryText>Project Channel</StyledCategoryText>
            <StyledSubCategoryText>Telegram</StyledSubCategoryText>
            <StyledSubCategoryText>Twitter</StyledSubCategoryText>
          </StyledItem3>
          <StyledItem4 className="footer-column">
            <StyledCategoryText>Blockchain Information</StyledCategoryText>
            <StyledSubCategoryText>LD Token Address</StyledSubCategoryText>
            <StyledSubCategoryText>LD Token Address</StyledSubCategoryText>
          </StyledItem4>
          <StyledItem5 className="footer-column last-column">
            <StyledWrapSubcribe className="footer-subscribe">
              <StyledSubcribeTitle>
                SUBSCRIBE TO OUR NEWSLETTER
              </StyledSubcribeTitle>
              <StyledFrom className="footer-form">
                <StyledInput onChange={() => {}} placeholder="Your email address"></StyledInput>
                <Button text="Subscribe" size="md" />
              </StyledFrom>
            </StyledWrapSubcribe>
          </StyledItem5>
        </StyledWrap>
      </Container>
    </Container>
  )
}

const StyledWrap = styled.div`
  display: flex;
  margin-top: 97px;
  margin-left: 60px;
  width: 92%;
  justify-content: space-between;
  
  @media (max-width: 430px) {
    margin-left: 0;
  }
`

const StyledItem1 = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledItem2 = styled(StyledItem1)`
`

const StyledItem3 = styled(StyledItem1)`
`

const StyledItem4 = styled(StyledItem1)`
`

const StyledItem5 = styled.div`
  width: 300px;
`

const StyledLogo = styled.div`
`

const StyledWrapSubcribe = styled.div`
`

const StyledFrom = styled.div`
  display: flex;
  
  & > button {
    width: 114px;
  }
`

const StyledInput = styled.input`
  border: 4px solid black;
  color: ${(props) => props.theme.color.grey[600]};
  font-size: 23px;
  width: 200px;
  height: 53px;
  background-color: #ffe6c9;
  @media (max-width: 430px) {
    width: 20px;
  }
`

const StyledSubcribeTitle = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #F4F8F9;
  --stroke-color: #560202;
  --stroke-width: 4px;
  text-shadow: var(--stroke-width) 0 0 var(--stroke-color), calc(var(--stroke-width) * -1) 0 0 var(--stroke-color), 0 var(--stroke-width) 0 var(--stroke-color), 0 calc(var(--stroke-width) * -1) 0 var(--stroke-color);
`

const StyledCategoryText = styled.h3`
  color: ${(props) => props.theme.color.white};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 100%;
  text-align: start;
  margin-bottom: 7px;
  padding: 0;
`

const StyledSubCategoryText = styled.a`
  color: ${(props) => props.theme.color.white};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-align: start;
  margin-top: 25px;
  padding: 0;
  cursor: pointer;
  &:hover {
    color: white;
  }
`

export default HomePageSection1
