import React, {useContext, useMemo} from 'react'
import styled, {ThemeContext} from 'styled-components'

import {Link} from 'react-router-dom'
import BtnOrange from '../../assets/img/background/btn_orange.png'
import BtnBlue from '../../assets/img/background/btn_blue.png'
import AccountStyledButton from "../AccountStyledButton";

interface ButtonProps {
    account?: string,
    children?: React.ReactNode,
    disabled?: boolean,
    href?: string,
    onClick?: () => void,
    size?: 'sm' | 'md' | 'lg' | 'xl',
    text?: string,
    to?: any,
    variant?: 'default' | 'secondary' | 'tertiary'
    bgColor?: 'orange' | 'blue'
    className?: string
}

const Button: React.FC<ButtonProps> = ({
                                           account,
                                           children,
                                           disabled,
                                           href,
                                           onClick,
                                           size,
                                           text,
                                           to,
                                           variant,
                                           bgColor,
                                           className,
                                       }) => {
    const {color, spacing} = useContext(ThemeContext)

    let buttonColor: string
    switch (variant) {
        case 'secondary':
            buttonColor = color.grey[500]
            break
        case 'default':
        default:
            buttonColor = color.white
    }

    let boxShadow: string
    let height: string
    let buttonPadding: number
    let fontSize: number

    switch (size) {
        case 'sm':
            boxShadow = `4px 4px 8px ${color.grey[300]},
        -8px -8px 16px ${color.grey[100]}FF;`
            buttonPadding = spacing[3]
            height = '40px'
            fontSize = 14
            break
        case 'lg':
            boxShadow = `6px 6px 12px ${color.grey[300]},
        -12px -12px 24px ${color.grey[100]}ff;`
            buttonPadding = spacing[4]
            height = '60px'
            fontSize = 16
            break
        case 'xl':
            buttonPadding = spacing[4]
            height = '72px'
            fontSize = 16
            break
        case 'md':
        default:
            boxShadow = `6px 6px 12px ${color.grey[300]},
        -12px -12px 24px -2px ${color.grey[100]}ff;`
            buttonPadding = spacing[4]
            height = '52px'
            fontSize = 16
    }

    const ButtonChild = useMemo(() => {
        if (to) {
            return <StyledLink to={to}>{text}</StyledLink>
        } else if (href) {
            return <StyledExternalLink href={href} target="__blank">{text}</StyledExternalLink>
        } else {
            return text
        }
    }, [href, text, to])
    return (
        <StyledButton
            account={account}
            className={className}
            boxShadow={boxShadow}
            color={buttonColor}
            disabled={disabled}
            fontSize={fontSize}
            onClick={onClick}
            padding={buttonPadding}
            height={height}
            bgColor={bgColor}
        >
            {children}
            {ButtonChild}
        </StyledButton>
    )
}

interface StyledButtonProps {
    boxShadow: string,
    color: string,
    disabled?: boolean,
    fontSize: number,
    padding: number,
    height: string,
    bgColor: string
}

const StyledButton = styled(AccountStyledButton)<StyledButtonProps>`
  align-items: center;
    /* background-color: ${props => props.theme.color.grey[200]}; */
  /* border: 0; */
    /* border-radius: 12px;
  box-shadow: ${props => props.boxShadow}; */
  color: ${props => !props.disabled ? props.color : `${props.color}55`};
  background-image: ${props => props.bgColor == 'blue' ? `url(${BtnBlue})` : `url(${BtnOrange})`};
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  /* display: flex; */
  font-size: ${props => props.fontSize}px;
  line-height: 16px;
  font-weight: 700;
  font-style: normal;
  height: ${props => props.height};
  justify-content: center;
  outline: none;
  padding-left: ${props => props.padding}px;
  padding-right: ${props => props.padding}px;
  pointer-events: ${props => !props.disabled ? undefined : 'none'};
  width: auto;
  border: none;

  &:hover {
      /* background-color: ${props => props.theme.color.grey[100]}; */
  }
`

const StyledLink = styled(Link)`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 56px;
  justify-content: center;
  margin: 0 ${props => -props.theme.spacing[4]}px;
  padding: 0 ${props => props.theme.spacing[4]}px;
  text-decoration: none;
`

const StyledExternalLink = styled.a`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 56px;
  justify-content: center;
  margin: 0 ${props => -props.theme.spacing[4]}px;
  padding: 0 ${props => props.theme.spacing[4]}px;
  text-decoration: none;
`

export default Button