import React, {useEffect} from 'react'
import './ListEquip.css'
import EquipItem from './EquipItem'
import {Contract} from "web3-eth-contract";
import {useWallet} from "use-wallet";
import {getAvatarUrl, useFetchState} from "../../../utils";
import {Modal} from "react-bootstrap";
import FilterCard from "../../Cards/components/FilterCard";
import CardItemConfirm from "../../Cards/components/CardItemConfirm";
import Button from "../../../components/Button";
import {heroArmory, heroPool, lgdHero} from "../../../sushi/utils";
import {CardQuality} from "../../../utils/card-quality";
import styled from "styled-components";

interface ListEquipProps {
    heroPoolContract: Contract
    heroContract: Contract
    lgdHeroContract: Contract
    web3: any
    stakedCards: any[]
    changeStakedCards: () => void
}

const ListEquip: React.FC<ListEquipProps> = (
    {
        heroPoolContract,
        heroContract,
        lgdHeroContract,
        web3,
        stakedCards,
        changeStakedCards
    }
) => {
    const {account} = useWallet()
    const pageSize = 5
    const [list, setList] = useFetchState([])
    const [myCard, setMyCard] = useFetchState({})
    const [myCardRows, setMyCardRows] = useFetchState([])
    const [fullMyCardRows, setFullMyCardRows] = useFetchState([])
    const [searchValue, setSearchValue] = useFetchState('')
    const [showApprove, setShowApprove] = useFetchState(false)
    const [showListSelectCard, setShowListSelectCard] = useFetchState(false)
    const [submitMyCardLoading, setSubmitMyCardLoading] = useFetchState(false)
    const [allowanceLoading, setAllowanceLoading] = useFetchState(false)
    const [allowance, setAllowance] = useFetchState(false)

    const handleClickConfirm = (e: any, index: number) => {
        const myCard = myCardRows[index]
        setMyCard(myCard)
    }
    const submitMyCard = () => {
        if (heroPoolContract && myCard?.tokenId && account) {
            setSubmitMyCardLoading(true)
            heroPool.stake(heroPoolContract, myCard.tokenId, account).catch(() => {
                setShowListSelectCard(false)
                setMyCardRows([])
                setSubmitMyCardLoading(false)
                changeStakedCards()
                setMyCard({})
            }).finally(() => {
                setShowListSelectCard(false)
                setMyCardRows([])
                setSubmitMyCardLoading(false)
                changeStakedCards()
                setMyCard({})
            })
        }
    }

    function onKeydownSearch(e: any) {
        if (e.keyCode === 13) {
            search()
        }
    }

    function search() {
        let list = [...fullMyCardRows].filter(item => {
            if (searchValue === '') {
                return true
            }
            try {
                return new RegExp(searchValue, 'i').test(item.name)
            } catch (e) {
                return item.name.includes(searchValue)
            }
        })
        setMyCardRows(list)
    }

    const getApprove = () => {
        setAllowanceLoading(true)
        lgdHero.approvalForAll(lgdHeroContract, heroPoolContract.options.address, account).then(res => {
            setAllowance(res)
        }).finally(() => {
            setAllowanceLoading(false)
        })
    }
    const removeCard = (item: any) => {
        if (item.removing) {
            return
        }
        let newList = list.map((card: any) => {
            if (card.tokenId === item.tokenId) {
                card.removing = true
            }
            return card
        })
        setList(newList)
        heroPool.withdraw(heroPoolContract, item.tokenId, account).then(() => {
            changeStakedCards()
        }).catch(e => {
            let newList = list.map((card: any) => {
                if (card.tokenId === item.tokenId) {
                    card.removing = false
                }
                return card
            })
            setList(newList)
        }).finally(() => {
            let newList = list.map((card: any) => {
                if (card.tokenId === item.tokenId) {
                    card.removing = true
                }
                return card
            })
            setList(newList)
        })
    }
    useEffect(() => {
        if (heroPoolContract && lgdHeroContract && account) {
            setAllowanceLoading(true)
            lgdHero.isApprovedForAll(lgdHeroContract, heroPoolContract.options.address, account).then(isApproved => {
                setAllowance(isApproved)
            }).catch(() => {
                setAllowanceLoading(false)
            }).finally(() => {
                setAllowanceLoading(false)
            })
        }
    }, [heroPoolContract, lgdHeroContract, account])

    useEffect(() => {
        let newList = [...stakedCards]
        if (newList.length < pageSize + 1) {
            for (let i = 0; i < pageSize - stakedCards.length; i++) {
                newList.push({
                    key: Math.random().toString()
                })
            }
        } else {
            newList.push({
                key: Math.random().toString()
            })
        }
        setList(newList)
    }, [stakedCards])

    useEffect(() => {
        if (heroContract && showListSelectCard) {
            heroArmory.getHeros(heroContract, account).then(res => {
                const all = res.map((item: any) => {
                    let q = CardQuality[item.quality]
                    return {
                        name: item.name,
                        quality: q.name,
                        fightRate: q.fight,
                        cardShowRate: q.rate,
                        fight: item.fight,
                        prop: item.prop,
                        tokenId: item.tokenId,
                        imgUrl:  getAvatarUrl(item),
                    }
                })
                setMyCardRows(all)
                setFullMyCardRows(all)
            })
        }
    }, [heroContract, web3, account, showListSelectCard, stakedCards])

    return (
        <div className="list__equip">
            <h2>Has equiped {stakedCards.length} cards</h2>
            <div className="list__equip--item">
                {
                    list.map((card: any) => <EquipItem
                        onClick={() => allowance ? (card.tokenId ? removeCard(card) : setShowListSelectCard(true)) : setShowApprove(true)}
                        key={card.tokenId || card.key}
                        card={card}
                    />)
                }
            </div>
            <div className="list-select-card">
                <Modal
                    show={showListSelectCard}
                    onHide={() => setShowListSelectCard(false)}
                    className="list-card-modal list-select-card "
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Select a card</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FilterCard
                            onChange={(e: any) => {
                                setSearchValue(e.target.value)
                            }}
                            onKeyDown={(e: any) => {
                                onKeydownSearch(e)
                            }}
                            onClick={search}
                            value={searchValue}
                        />
                        <div className="list">
                            {myCardRows.map((card: any, index: number) => <CardItemConfirm
                                key={index}
                                activeClass={card.tokenId === myCard.tokenId ? 'active' : ''}
                                handleClickConfirm={(e: any) => handleClickConfirm(e, index)}
                                card={card}
                            />)}
                        </div>
                        <div className="confirm-btn">
                            <Button text={submitMyCardLoading ? 'Confirm...' : 'Confirm'} size="sm"
                                    disabled={myCardRows.length === 0 || submitMyCardLoading}
                                    onClick={submitMyCard}/>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={showApprove && !allowance}
                    onHide={() => {
                        setShowApprove(false)
                        setShowListSelectCard(true)
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Approve</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ApproveBox>
                            <Button text={allowanceLoading ? 'Approve...' : 'Approve'} size="sm"
                                    disabled={allowanceLoading}
                                    onClick={getApprove}/>
                        </ApproveBox>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

const ApproveBox = styled.div`
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export default ListEquip
