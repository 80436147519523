import React, {useCallback, useEffect} from 'react'
import Button from '../../../components/Button'
import {Modal} from 'react-bootstrap'
import carousel1 from '../../../assets/img/card/card-1.png'
import arrowleft from '../../../assets/img/arrow-left.png'
import arrowright from '../../../assets/img/arrow-right.png'
import burn from '../../../assets/img/burn.png'
import fire from '../../../assets/img/fire.png'
import Slider from 'react-slick'
import calabashNormalHero from '../../../assets/img/card/calabashNormalHero.png'
import burnsuccess from '../../../assets/img/background/burn-success.png'
import './Carousels.css'
import FilterCard from './FilterCard'
import CardItemConfirm from './CardItemConfirm'
import spinnerCard from '../../../assets/img/spinner-card.png'
import fireball from '../../../assets/img/fireball.png'
import {useWallet} from "use-wallet";
import {approve, heroArmory, isZeroAddress, lgdHero, shop} from '../../../sushi/utils'
import {Contract} from "web3-eth-contract";
import {getAllowance, getContract} from "../../../utils/erc20";
import {provider} from "web3-core";
import BigNumber from "bignumber.js";
import {generateLink, getAvatarUrl, useFetchState} from "../../../utils";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {CardQuality} from "../../../utils/card-quality";
import {contractAddresses} from "../../../sushi/lib/constants";

interface CarouselsProps {
    shopContract: Contract
    heroContract: Contract
    lgdHeroContract: Contract
    lgdTokenContract: Contract
    web3: any
}

const defaultCard: any = {
    name: '',
    quality: '',
    fightRate: '',
    cardShowRate: '',
    prop: '',
    fight: ''
}
const Carousels: React.FC<CarouselsProps> = (
    {
        shopContract,
        heroContract,
        lgdHeroContract,
        lgdTokenContract,
        web3
    }) => {
    const {account, ethereum}: { account: string, ethereum: any } = useWallet()
    const {i18n} = useTranslation()
    const history = useHistory()
    const [allowanceLoading, setAllowanceLoading] = useFetchState(false)
    const [burnApproveBtnName, setBurnApproveBtnName] = useFetchState('')
    const [burnApproveLoading, setBurnApproveLoading] = useFetchState(false)
    const [requestedApproval, setRequestedApproval] = useFetchState(false)
    const [rows, setRows] = useFetchState([])
    const [myCard, setMyCard] = useFetchState(defaultCard)
    const [myCardRows, setMyCardRows] = useFetchState([])
    const [fullMyCardRows, setFullMyCardRows] = useFetchState([])
    const [searchValue, setSearchValue] = useFetchState('')
    const [cardIndex, setCardIndex] = useFetchState(-1)
    const [show, setShow] = useFetchState(false)
    const [showBurn, setShowBurn] = useFetchState(false);
    const [showSuccess, setShowSuccess] = useFetchState(false)
    const [byCard, setByCard] = useFetchState(defaultCard)

    const [showConfirmSuccess, setShowConfirmSuccess] = useFetchState(false)
    const [showListSelectCard, setShowListSelectCard] = useFetchState(false)
    const [showLoadingBurn, setShowLoadingBurn] = useFetchState(false)
    const [showLoadingBurnHero, setShowLoadingBurnHero] = useFetchState(false)

    const handleClose = () => setShow(false)
    const handleCloseBurn = () => setShowBurn(false);
    const handleShow = () => setShow(true)

    const handleCloseShowSuccess = () => setShowSuccess(false)
    const handleShowShowSuccess = () => setShowSuccess(true)

    const handleCloseConfirmSuccess = () => setShowConfirmSuccess(false)
    const handleShowConfirmSuccess = () => setShowConfirmSuccess(true)

    const closeSelectCard = () => setShowListSelectCard(false)
    const showSelectCard = useCallback(async () => {
        if (lgdHeroContract && shopContract && account && ethereum) {
            setBurnApproveLoading(true)
            let allowance721: boolean = false
            let allowanceErc20: boolean = false
            setBurnApproveBtnName('Allowance all...')
            allowance721 = await lgdHero.isApprovedForAll(lgdHeroContract, shopContract.options.address, account)
            if (!allowance721) {
                setBurnApproveBtnName('Approve for all...')
                const hash721 = await lgdHero.approvalForAll(lgdHeroContract, shopContract.options.address, account)
                allowance721 = !!hash721
            }
            if (allowance721) {
                setBurnApproveBtnName('allowance...')
                const alloErc20 = await getAllowance(lgdTokenContract, shopContract, account)
                allowanceErc20 = new BigNumber(alloErc20).toNumber() !== 0
                if (!allowanceErc20) {
                    setBurnApproveBtnName('approve...')
                    const hashErc20 = await approve(lgdTokenContract, shopContract, account)
                    allowanceErc20 = !!hashErc20
                }
            }
            setBurnApproveBtnName('')

            setShowListSelectCard(allowance721 && allowanceErc20)
            setBurnApproveLoading(false)
        }
    }, [lgdHeroContract, shopContract, ethereum, account, getContract, getAllowance, approve, contractAddresses])

    const closeLoadingBurn = () => setShowLoadingBurn(false)
    const openLoadingBurn = () => setShowLoadingBurn(true)

    const closeLoadingBurnHero = () => setShowLoadingBurnHero(false)
    const openLoadingBurnHero = () => setShowLoadingBurnHero(true)

    const handleClickConfirm = (e: any, index: number) => {
        const myCard = myCardRows[index]
        setMyCard(myCard)
    }

    const openBurnDialog = useCallback(async () => {
        if (shopContract && myCard.tokenId && account) {
            setShowBurn(false)
            setShowListSelectCard(false)
            setShowLoadingBurn(true)
            // const price = await shop.heroAmount(shopContract, account)
            shop.reRandomHero(shopContract, myCard.tokenId, undefined, account).then(res => {
                const item = res?.events?.Buy?.returnValues?.hero
                let q = CardQuality[item.quality]

                const cardBack: any = {
                    name: item.name,
                    quality: q.name,
                    fightRate: q.fight,
                    cardShowRate: q.rate,
                    fight: item.fight,
                    prop: item.prop,
                    tokenId: item.tokenId,
                    imgUrl: getAvatarUrl(item)
                }
                setMyCard(cardBack)
                setShowConfirmSuccess(true)
            }).finally(() => {
                setShowLoadingBurn(false)
                closeSelectCard()
            })
        }
    }, [shopContract, myCard, account])
    const handleApprove = useCallback(async () => {
        let card = rows[cardIndex]

        if (card) {
            setRequestedApproval(true)
            const contract = getContract(ethereum as provider, card.payToken)
            const txHash = await approve(contract, shopContract, account)
            if (txHash) {
                setRequestedApproval(false)
                card.allowance = true
                rows[cardIndex] = card;
            } else {
                setRequestedApproval(false)
            }
            setRequestedApproval(false)
        }
    }, [cardIndex, setRequestedApproval, shopContract, account])

    const openBuyDialog = () => {
        let card = rows[cardIndex]
        if (card && shopContract && ethereum && account) {
            setShow(true)
            shop.buy(shopContract, cardIndex, card.payToken, card.price, account).then(res => {
                const item = res?.events?.Buy?.returnValues?.hero
                let q = CardQuality[item.quality]

                const cardBack: any = {
                    name: item.name,
                    quality: q.name,
                    fightRate: q.fight,
                    cardShowRate: q.rate,
                    fight: item.fight,
                    prop: item.prop,
                    tokenId: item.tokenId,
                    imgUrl: getAvatarUrl(item),
                }
                setShowSuccess(true)
                setByCard(cardBack)
                setShow(false)
            }).catch(e => {
                setShow(false)
            })
        }
    }

    function SampleNextArrow(props: any) {
        const {className, style, onClick} = props
        return (
            <div className={className} style={{...style, display: 'block'}}>
                <img src={arrowright} alt="" onClick={onClick}/>
            </div>
        )
    }

    function SamplePrevArrow(props: any) {
        const {className, style, onClick} = props
        return (
            <div className={className} style={{...style, display: 'block'}}>
                <img src={arrowleft} alt="" onClick={onClick}/>
            </div>
        )
    }

    function handleClickItem(e: any, index: number) {
        setCardIndex(index)
        const slides = document.getElementsByClassName('slick-slide');
        for (var i = 0; i < slides.length; i++) {
            slides[i].classList.remove('active');
        }
        e.currentTarget.parentNode.parentElement.classList.add('active')
    }

    function onKeydownSearch(e: any) {
        if (e.keyCode === 13) {
            search()
        }
    }

    function search() {
        let list = [...fullMyCardRows].filter(item => {
            if (searchValue === '') {
                return true
            }
            try {
                return new RegExp(searchValue, 'i').test(item.name)
            } catch (e) {
                return item.name.includes(searchValue)
            }
        })
        setMyCardRows(list)
    }

    useEffect(() => {
        let card = rows[cardIndex]
        const getAl = async () => {
            const contract = getContract(ethereum as provider, card.payToken)
            const allo = await getAllowance(contract, shopContract, account)
            card.allowance = new BigNumber(allo).toNumber() !== 0
            rows[cardIndex] = card
            setAllowanceLoading(false)
        }

        if (card) {
            if (card.status !== '1') {
                card.allowance = true
                rows[cardIndex] = card
            } else {
                setAllowanceLoading(true)
                let is = isZeroAddress(card.payToken)
                if (is) {
                    card.allowance = true
                    rows[cardIndex] = card
                    setAllowanceLoading(false)
                } else {
                    getAl().then()
                }
            }
        }
    }, [cardIndex, shopContract, ethereum, account])

    useEffect(() => {
        if (shopContract && rows.length === 0) {
            shop.getAllBlindBox(shopContract).then(res => {
                let statusName: any = {
                    0: 'Coming soon',
                    1: 'Buy',
                    2: 'Locked',
                    3: 'sold out',
                    4: 'Hidden'
                }
                const all = res.map((item: any) => {
                    return {
                        statusName: statusName[item.status],
                        status: item.status,
                        supply: item.supply,
                        remaining: item.remaining,
                        price: web3.utils.fromWei(item.price),
                        payToken: item.payToken,
                    }
                }).filter((card: any) => {
                    // 0: pending 1:Buy 2:lock 3:soldOut  4:hide
                    return card.status !== '4'
                }).map((card: any) => {
                    if (card.status === '1') {
                        const contract = getContract(ethereum as provider, card.payToken)
                        return contract.methods.symbol().call().then((res: any) => {
                            return {
                                ...card,
                                symbol: res
                            }
                        })
                    } else {
                        card.symbol = ''
                        return Promise.resolve(card)
                    }
                })

                Promise.all(all).then((list: any) => {
                    console.log('cardshow', list)
                    setRows(list)
                })
            })
        }
    }, [shopContract, web3])
    useEffect(() => {
        if (heroContract && myCardRows.length === 0 && showListSelectCard) {
            heroArmory.getHeros(heroContract, account).then(res => {
                const all = res.map((item: any, index: number) => {
                    let q = CardQuality[item.quality]
                    return {
                        name: item.name,
                        quality: q.name,
                        fightRate: q.fight,
                        cardShowRate: q.rate,
                        fight: item.fight,
                        prop: item.prop,
                        tokenId: item.tokenId,
                        imgUrl: getAvatarUrl(item),
                    }
                })
                setMyCardRows(all)
                setFullMyCardRows(all)
            })
        }
    }, [heroContract, web3, account, showListSelectCard])

    const settings = {
        dots: false,
        infinite: false, // loop
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        nav: true,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }
    return (
        <div className="card-shop">
            <div className="card__carousels">
                <div className="carousels move-up">
                    <div className="text-center">
                        <div className="container-carousels">
                            <Slider {...settings}>
                                {rows.map((item: any, index: number) =>
                                    <div className="item" key={index}
                                         onClick={(e) => handleClickItem(e, index)}>
                                        <span>Left: {item.remaining}</span>
                                        <p>Spend your HT and you will receive a random Hero card</p>
                                        <img src={carousel1} alt=""/>
                                        {
                                            item.status === '1' ? <h2>{item.price} {item.symbol}</h2> :
                                                <h3>{item.statusName}</h3>
                                        }
                                    </div>
                                )}
                            </Slider>
                        </div>
                        <div className="btn-buy">
                            {
                                (allowanceLoading || requestedApproval) ? <Button
                                    disabled={true}
                                    text="Loading..."
                                    size="md"
                                    bgColor="blue"
                                /> : (
                                    (!rows[cardIndex] || rows[cardIndex].allowance || cardIndex === -1) ? <Button
                                        disabled={!rows[cardIndex] || rows[cardIndex].status !== '1'}
                                        text="Buy"
                                        size="md"
                                        bgColor="blue"
                                        onClick={openBuyDialog}
                                    /> : <Button
                                        disabled={rows[cardIndex].status !== '1'}
                                        text={rows[cardIndex].allowance ? rows[cardIndex].statusName : 'Approve'}
                                        size="md"
                                        bgColor="blue"
                                        onClick={handleApprove}
                                    />
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
            <div className="card__carousels custom-bottom">
                <div className="carousels">
                    <div className="text-center">
                        <div className="burn">
                            <img src={burn} alt=""/>
                            <img className="fire" src={fire} alt=""/>
                            <p>
                                Burn one of your existing Heros and pay xxx LD Tokens to receive a
                                new random Hero
                            </p>
                            <Button disabled={burnApproveLoading}
                                    text={burnApproveLoading ? burnApproveBtnName : 'Burn'}
                                    size="md" onClick={showSelectCard}/>
                        </div>
                        {/* spinner */}
                        <div className="spinner">
                            <Modal show={show} onHide={handleClose} className="spinner-buy">
                                <Modal.Header closeButton>
                                    {/* <img src={bgspinnerburn} alt="" /> */}
                                </Modal.Header>
                                <Modal.Body>

                                    <div className="pending">
                                        <div className="white-bar"></div>
                                        <figure className="wrap">
                                            <img className="img-fluid buy-only" src={spinnerCard} alt=""/>
                                        </figure>
                                        <div className="white-bar"></div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                        {' '}
                        {/* end spinner */}
                        {/* burn */}
                        <div className="spinner">
                            <Modal
                                show={showBurn}
                                className="spinner-buy burning"
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Body>
                                    <div className="pending">
                                        <figure className="wrap">
                                            <img className="img-fluid" src={spinnerCard} alt=""/>
                                        </figure>
                                        <div className="group">
                                            <div className="group-fire">
                                                <figure className="move-down"><img className="fire-ball" src={fireball}
                                                                                   alt=""/></figure>
                                                <figure className="move-up"><img className="fire-ball" src={fireball}
                                                                                 alt=""/></figure>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                        {' '}
                        {/* end burn */}
                        <div>
                            <Modal
                                show={showSuccess}
                                onHide={handleCloseShowSuccess}
                                className="burn-success"
                            >
                                <Modal.Header closeButton>
                                    <img src={burnsuccess} alt=""/>
                                    <h2 className="title-burn-success">
                                        CONGRATULATION ON GETTING:
                                    </h2>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="group-info">
                                        <img src={byCard.imgUrl}/>
                                        <div className="information">
                                            <p>Name: {byCard.name}</p>
                                            <p>战斗力加成: {byCard.fightRate}%</p>
                                            <p>卡片出现概率: {byCard.cardShowRate}%</p>
                                            <p>Fight: {byCard.fight}</p>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Button text="View card" onClick={() => {
                                    handleCloseShowSuccess()
                                    history.push(generateLink(i18n, '/cardshop/mycard'))
                                }} size="md"/>
                            </Modal>
                        </div>
                        {/* end spinner */}
                        <div>
                            <Modal
                                show={showConfirmSuccess}
                                onHide={handleCloseConfirmSuccess}
                                className="burn-success show-confirm-card"
                            >
                                <Modal.Header closeButton>
                                    <img src={burnsuccess} alt=""/>
                                    <h2 className="title-burn-success">
                                        BURNING SUCCESSFULLY AND CONGRATULATION ON GETTING:
                                    </h2>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="group-info">
                                        <img src={myCard.imgUrl}/>
                                        <div className="information">
                                            <p>Name: {myCard.name}</p>
                                            <p>战斗力加成: {myCard.fightRate}%</p>
                                            <p>卡片出现概率: {myCard.cardShowRate}%</p>
                                            <p>Fight: {myCard.fight}</p>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Button text="View card" size="md" onClick={() => {
                                    history.push(generateLink(i18n, '/cardshop/mycard'))
                                }}/>
                            </Modal>
                        </div>
                        <div className="list-select-card">
                            <Modal
                                show={showListSelectCard}
                                onHide={closeSelectCard}
                                className="list-card-modal list-select-card "
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Select a card</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <FilterCard
                                        onChange={(e: any) => {
                                            setSearchValue(e.target.value)
                                        }}
                                        onKeyDown={(e: any) => {
                                            onKeydownSearch(e)
                                        }}
                                        onClick={search}
                                        value={searchValue}
                                    />
                                    <div className="list">
                                        {myCardRows.map((card: any, index: number) => <CardItemConfirm
                                            key={index}
                                            activeClass={card.tokenId === myCard.tokenId ? 'active' : ''}
                                            handleClickConfirm={(e: any) => handleClickConfirm(e, index)}
                                            card={card}
                                        />)}
                                    </div>
                                    <div className="confirm-btn">
                                        <Button text="Confirm" size="sm"
                                                disabled={myCardRows.length === 0 || showLoadingBurn}
                                                onClick={openBurnDialog}/>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                        <div className="loading-burn">
                            <Modal
                                show={showLoadingBurn}
                                onHide={closeLoadingBurn}
                                className="loading-burn"
                            >
                                <Modal.Body>
                                    <div className="group-info">
                                        <img src={carousel1}/>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                        <div className="loading-burn-hero">
                            <Modal
                                show={showLoadingBurnHero}
                                onHide={closeLoadingBurnHero}
                                className="loading-burn-hero"
                            >
                                <Modal.Body>
                                    <div className="group-info">
                                        <img src={carousel1}/>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Carousels
