import React, {useEffect} from 'react'
import {Container} from 'reactstrap'
import Nav from './components/Nav'
import menutop from '../../assets/img/background/Menu-top.png'
import {useLocation} from "react-router-dom";

interface TopBarProps {
  onPresentMobileMenu: () => void
}

const TopBar: React.FC<TopBarProps> = () => {
    const {pathname} = useLocation()
    const win: any = window
    useEffect(()=>{
        win.document.scrollingElement.scrollTop = 0
    },[pathname])

  return (
    <Container className="themed-container" fluid="xl" style={{
      backgroundColor: 'black',
      backgroundImage: 'url(' +menutop + ')'
    }}>
        <Nav />
    </Container>
  )
}
export default TopBar
