import React from 'react'
import styled from 'styled-components'

import {Container} from 'reactstrap'
import Background from '../../../assets/img/background/homepage_battle_section.png'
import Button from '../../../components/Button'
import threeStar from '../../../assets/img/background/three-star.png';

interface HomePageSection1Props {}

const HomePageSection1: React.FC<HomePageSection1Props> = () => {
  return (
    <Container className="themed-container battle" fluid="xl" style={{
      background: `url(${Background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      marginTop: -1
    }}>
      <Container className="themed-container battle-container" fluid="lg">
        <StyledWrap>
          <figure>
            <img className="img-fluid" src={threeStar} alt="three-start"/>
          </figure>
          <StyledTitle>Equip your cards and get more rewards</StyledTitle>

          <StyledWrapButton>
            <Button text='Introduce' size='md' />
          </StyledWrapButton>
        </StyledWrap>
        
      </Container>
    </Container>
  )
}

const StyledWrap = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => props.theme.spacing[6]}px;
  padding-top: 25px;
  margin: 0 auto;
  width: 66%;
`

const StyledWrapButton = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding-bottom: ${(props) => props.theme.spacing[6]}px;
  padding-top: 78px;
  margin: 0 auto;
  width: 36%;
  justify-content: space-around;
`

const StyledTitle = styled.h1`
  color: ${(props) => props.theme.color.white};
  font-style: normal;
  font-weight: normal;
  font-size: 56px;
  line-height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  --stroke-color: #560202;
  --stroke-width: 4px;
  color: white;
  text-shadow: var(--stroke-width) 0 0 var(--stroke-color), calc(var(--stroke-width) * -1) 0 0 var(--stroke-color), 0 var(--stroke-width) 0 var(--stroke-color), 0 calc(var(--stroke-width) * -1) 0 var(--stroke-color);
`

export default HomePageSection1
