import BigNumber from 'bignumber.js'
import {ethers} from 'ethers'

BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80,
})

const GAS_LIMIT = {
    STAKING: {
        DEFAULT: 200000,
        SNX: 850000,
    },
}

export const isZeroAddress = address => {
    return address === '0x' + ('0'.repeat(40))
}

export const getMasterChefAddress = (sushi) => {
    return sushi && sushi.masterChefAddress
}
export const getSushiAddress = (sushi) => {
    return sushi && sushi.sushiAddress
}
export const getWethContract = (sushi) => {
    return sushi && sushi.contracts && sushi.contracts.weth
}

export const getMasterChefContract = (sushi) => {
    return sushi && sushi.contracts && sushi.contracts.masterChef
}
export const getSushiContract = (sushi) => {
    return sushi && sushi.contracts && sushi.contracts.sushi
}

export const getFarms = (sushi) => {
    return sushi
        ? sushi.contracts.pools.map(
            ({
                 pid,
                 name,
                 symbol,
                 icon,
                 tokenAddress,
                 tokenSymbol,
                 tokenContract,
                 lpAddress,
                 lpContract,
             }) => ({
                pid,
                id: symbol,
                name,
                lpToken: symbol,
                lpTokenAddress: lpAddress,
                lpContract,
                tokenAddress,
                tokenSymbol,
                tokenContract,
                earnToken: 'sushi',
                earnTokenAddress: sushi.contracts.sushi.options.address,
                icon,
            }),
        )
        : []
}

export const getPoolWeight = async (masterChefContract, pid) => {
    const {allocPoint} = await masterChefContract.methods.poolInfo(pid).call()
    const totalAllocPoint = await masterChefContract.methods
        .totalAllocPoint()
        .call()
    return new BigNumber(allocPoint).div(new BigNumber(totalAllocPoint))
}

export const getEarned = async (masterChefContract, pid, account) => {
    return masterChefContract.methods.pendingSushi(pid, account).call()
}

export const getTotalLPWethValue = async (
    masterChefContract,
    wethContract,
    lpContract,
    tokenContract,
    pid,
) => {
    // Get balance of the token address
    const tokenAmountWholeLP = await tokenContract.methods
        .balanceOf(lpContract.options.address)
        .call()
    const tokenDecimals = await tokenContract.methods.decimals().call()
    // Get the share of lpContract that masterChefContract owns
    const balance = await lpContract.methods
        .balanceOf(masterChefContract.options.address)
        .call()
    // Convert that into the portion of total lpContract = p1
    const totalSupply = await lpContract.methods.totalSupply().call()
    // Get total weth value for the lpContract = w1
    const lpContractWeth = await wethContract.methods
        .balanceOf(lpContract.options.address)
        .call()
    // Return p1 * w1 * 2
    const portionLp = new BigNumber(balance).div(new BigNumber(totalSupply))
    const lpWethWorth = new BigNumber(lpContractWeth)
    const totalLpWethValue = portionLp.times(lpWethWorth).times(new BigNumber(2))
    // Calculate
    const tokenAmount = new BigNumber(tokenAmountWholeLP)
        .times(portionLp)
        .div(new BigNumber(10).pow(tokenDecimals))

    const wethAmount = new BigNumber(lpContractWeth)
        .times(portionLp)
        .div(new BigNumber(10).pow(18))
    return {
        tokenAmount,
        wethAmount,
        totalWethValue: totalLpWethValue.div(new BigNumber(10).pow(18)),
        tokenPriceInWeth: wethAmount.div(tokenAmount),
        poolWeight: await getPoolWeight(masterChefContract, pid),
    }
}

export const approve = async (lpContract, masterChefContract, account) => {
    return lpContract.methods
        .approve(masterChefContract.options.address, ethers.constants.MaxUint256)
        .send({from: account})
}

export const getSushiSupply = async (sushi) => {
    return new BigNumber(await sushi.contracts.sushi.methods.totalSupply().call())
}

export const stake = async (masterChefContract, pid, amount, account) => {
    return masterChefContract.methods
        .deposit(
            pid,
            new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(),
        )
        .send({from: account})
        .on('transactionHash', (tx) => {
            console.log(tx)
            return tx.transactionHash
        })
}

export const unstake = async (masterChefContract, pid, amount, account) => {
    return masterChefContract.methods
        .withdraw(
            pid,
            new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(),
        )
        .send({from: account})
        .on('transactionHash', (tx) => {
            console.log(tx)
            return tx.transactionHash
        })
}
export const harvest = async (masterChefContract, pid, account) => {
    return masterChefContract.methods
        .deposit(pid, '0')
        .send({from: account})
        .on('transactionHash', (tx) => {
            console.log(tx)
            return tx.transactionHash
        })
}

export const getStaked = async (masterChefContract, pid, account) => {
    try {
        const {amount} = await masterChefContract.methods
            .userInfo(pid, account)
            .call()
        return new BigNumber(amount)
    } catch {
        return new BigNumber(0)
    }
}

export const redeem = async (masterChefContract, account) => {
    let now = new Date().getTime() / 1000
    if (now >= 1597172400) {
        return masterChefContract.methods
            .exit()
            .send({from: account})
            .on('transactionHash', (tx) => {
                console.log(tx)
                return tx.transactionHash
            })
    } else {
        alert('pool not active')
    }
}

export const shop = {
    /**
     * BlindBox
     *
     * uint256 status; // 0: pending 1:Buy 2:lock 3:soldOut  4:hide
     * uint256 supply; // total
     * uint256 remaining; //
     * uint256 price;
     * address payToken;
     * @param {*} shopContract
     * @returns  BlindBox[]
     */
    getAllBlindBox: async (shopContract) => {
        return await shopContract.methods.getAllBlindBox().call()
    },

    /**
     * buy and open BlindBox get hero
     * @param {*} shopContract
     * @param {*} index BlindBox index
     * @param {*} tokenAddress  tokenAddress
     * @param {*} account
     * @returns
     */
    buy: async (shopContract, index, tokenAddress, price, account) => {
        let params = {
            from: account
        }
        if (isZeroAddress(tokenAddress)) {
            params.value = new BigNumber(price).times(new BigNumber(10).pow(18)).toString()
        }

        return await shopContract.methods.buy(index).send(params)
    },
    /**
     * change name use lgd amount
     * @param {*} shopContract
     * @param {*} account
     * @returns
     */
    nameAmount: async (shopContract, account) => {
        return await shopContract.methods.nameAmount().call({from: account})
    },
    /**
     * reRandom hero use lgd amount
     * @param {*} shopContract
     * @param {*} account
     * @returns
     */
    heroAmount: async (shopContract, account) => {
        return await shopContract.methods.heroAmount().call({from: account})
    },
    /**
     * buy hero and reRandom hero give amount lgdToken
     * @param {*} shopContract
     * @param {*} account
     * @returns
     */
    giveAmount: async (shopContract, account) => {
        return await shopContract.methods.giveAmount().call({from: account})
    },
    /**
     * change hero name
     * Consumption of LGD must be approve lgd token
     * @param {*} shopContract
     * @param {*} tokenId
     * @param {*} name change name
     * @param {*} account
     * @returns
     */
    changeHeroName: async (shopContract, tokenId, name, account) => {
        return await shopContract.methods.changeHeroName(tokenId, name).send({from: account})
    },
    /**
     * use tokenId random hero
     * Consumption of LGD must be approve lgd token
     * @param {*} shopContract
     * @param {*} tokenId
     * @param {*} account
     * @returns
     */
    reRandomHero: async (shopContract, tokenId, price, account) => {
        let params = {
            from: account
        }
        if (isZeroAddress(tokenId) && price !== undefined) {
            params.value = new BigNumber(price).times(new BigNumber(10).pow(18)).toString()
        }
        return await shopContract.methods.reRandomHero(tokenId).send(params)
    },
}

export const heroArmory = {
    /**
     * hero open 1:open
     * @param {*} heroArmoryContract
     * @param {*} account
     * @returns
     */
    getHeroOpen: async (heroArmoryContract, account) => {
        return await heroArmoryContract.methods.getHeroOpen().call({from: account})
    },
    /**
     * get user heros
     * @param {*} shopContract
     * @param {*} index BlindBox index
     * @param {*} tokenAddress  tokenAddress
     * @param {*} account
     * @returns HeroInfo[]
     */
    getHeros: async (heroArmoryContract, account) => {
        return await heroArmoryContract.methods.getHeros(account).call({from: account})
    },

    /**
     * get heroInfo
     *
     * @param {*} heroArmoryContract
     * @param {*} tokenId
     * @param {*} account
     * @returns HeroInfo
     */
    getHeroById: async (heroArmoryContract, tokenId, account) => {
        return await heroArmoryContract.methods.getHeroById(tokenId).call({from: account})
    },

    /**
     * get hero fight
     * @param {*} heroArmoryContract
     * @param {*} tokenId
     * @param {*} account
     * @returns uint256
     */
    getHeroFight: async (heroArmoryContract, tokenId, account) => {
        return await heroArmoryContract.methods.getHeroFight(tokenId).call({from: account})
    },
}


/**
 * erc721
 */
export const lgdHero = {
    /**
     *
     * @param {*} lgdHeroContract
     * @param {*} operator heroArmoryAddress
     * @param {*} account
     * @returns
     */
    approvalForAll: async (lgdHeroContract, operator, account) => {
        return await lgdHeroContract.methods
            .setApprovalForAll(operator, true)
            .send({from: account})
    },
    /**
     * check approve
     * @param {*} lgdHeroContract
     * @param {*} operator heroArmoryAddress
     * @param {*} account
     * @returns bool
     */
    isApprovedForAll: async (lgdHeroContract, operator, account) => {
        return await lgdHeroContract.methods
            .isApprovedForAll(account, operator)
            .call({from: account})
    },
    /**
     *
     * @param {*} lgdHeroContract
     * @param {*} account
     * @returns
     */
    balanceOf: async (lgdHeroContract, account) => {
        return await lgdHeroContract.methods
            .balanceOf(account)
            .call({from: account})
    },
    /**
     * tokens
     * @param {*} lgdHeroContract
     * @param {*} ids
     * @param {*} account
     * @returns uint256[]
     */
    tokensOfOwner: async (lgdHeroContract, account) => {
        return await lgdHeroContract.methods
            .tokensOfOwner(account)
            .call({from: account})
    },

    /**
     *
     * @param {*} lgdHeroContract
     * @param {*} to address
     * @param {*} tokenId
     * @param {*} account
     * @returns
     */
    safeTransferFrom: async (lgdHeroContract, to, tokenId, account) => {
        return await lgdHeroContract.methods
            .safeTransferFrom(account, to, tokenId)
            .send({from: account})
    },
}

/**
 * hero pool
 */
export const heroPool = {
    /**
     *
     * @param {*} heroPoolContract
     * @param {*} account
     * @returns
     */
    earned: async (heroPoolContract, account) => {
        return await heroPoolContract.methods
            .earned(account)
            .call({from: account})
    },
    /**
     * check approve
     * @param {*} heroPoolContract
     * @param {*} tokenId tokenId
     * @param {*} account
     * @returns
     */
    stake: async (heroPoolContract, tokenId, account) => {
        return await heroPoolContract.methods
            .stake(tokenId)
            .send({from: account})
    },
    /**
     * withdraw
     * @param {*} heroPoolContract
     * @param {*} tokenId tokenId
     * @param {*} account
     * @returns
     */
    withdraw: async (heroPoolContract, tokenId, account) => {
        return await heroPoolContract.methods
            .withdraw(tokenId)
            .send({from: account})
    },

    /**
     * all fight
     * @param {*} heroPoolContract
     * @param {*} account
     * @returns uint256
     */
    balanceOfFight: async (heroPoolContract, account) => {
        return await heroPoolContract.methods
            .balanceOfFight(account)
            .call({from: account})
    },

    /**
     * stake all hero
     * @param {*} heroPoolContract
     * @param {*} account
     * @returns uint256[]
     */
    stakeOnwer: async (heroPoolContract, account) => {
        return await heroPoolContract.methods
            .stakeOnwer()
            .call({from: account})
    },

    /**
     * get reawrd
     * @param {*} heroPoolContract
     * @param {*} account
     * @returns
     */
    getReward: async (heroPoolContract, account) => {
        return await heroPoolContract.methods
            .getReward()
            .send({from: account})
    },

    /**
     * network total fights
     * @param {*} heroPoolContract
     * @param {*} account
     * @returns
     */
    totalFight: async (heroPoolContract, account) => {
        return await heroPoolContract.methods
            .totalFight()
            .call({from: account})
    },
}