import React from 'react'

import {useWallet} from 'use-wallet'
import useSushi from '../../hooks/useSushi'
import {getFarms} from '../../sushi/utils'

import Context from './context'
import {useFetchState} from "../../utils";

const Farms: React.FC = ({children}) => {
    const [unharvested, setUnharvested] = useFetchState(0)

    const sushi = useSushi()
    const {account} = useWallet()

    const farms = getFarms(sushi)

    return (
        <Context.Provider
            value={{
                farms,
                unharvested,
            }}
        >
            {children}
        </Context.Provider>
    )
}

export default Farms
