import React, {useCallback, useEffect} from 'react'
import {Tab, Tabs} from 'react-bootstrap'
import Carousels from './Carousels'
import './ListCard.css';
import SearchCard from './SearchCard'
import useSushi from "../../../hooks/useSushi";
import Button from '../../../components/Button'
import {useWallet} from "use-wallet";
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import {useHistory} from "react-router";
import {generateLink, useFetchState} from "../../../utils";
import {useTranslation} from "react-i18next";


const SlideCard: React.FC = () => {
    const {pathname} = useLocation()
    const {i18n} = useTranslation()
    const history = useHistory();
    const sushi: any = useSushi()

    const routerMap: any = {
        'card-shop': 'cardshop',
        'my-cards': 'cardshop/mycards',
    }
    const routerReverseMap: any = {
        'cardshop': 'card-shop',
        'mycards': 'my-cards',
    }
    let routerKey = pathname.match(/[^\/]*$/)[0]
    const defaultKey = routerReverseMap[routerKey]
    const [shopContract, setShopContract] = useFetchState(null)
    const [heroContract, setHeroContract] = useFetchState(null)
    const [lgdHeroContract, setLgdHeroContract] = useFetchState(null)
    const [lgdTokenContract, setLgdTokenContract] = useFetchState(null)
    const [defaultActiveKey, setDefaultActiveKey] = useFetchState(defaultKey)
    const [web3, setWeb3] = useFetchState(null)
    const {account} = useWallet()

    useEffect(() => {
        if (pathname) {
            if (pathname.endsWith('cardshop')) {
                setDefaultActiveKey('card-shop')
            } else {
                setDefaultActiveKey('my-cards')
            }
        }
    }, [pathname])
    useEffect(() => {
        if (sushi) {
            setShopContract(sushi.contracts.shop)
            setHeroContract(sushi.contracts.heroArmory)
            setLgdHeroContract(sushi.contracts.lgdHero)
            setLgdTokenContract(sushi.contracts.lgdToken)
            setWeb3(sushi.web3)
        }
    }, [sushi, account])

    const clickTab = useCallback((key) => {
        setDefaultActiveKey(key)
        let path = routerMap[key]
        history.push(generateLink(i18n, '/' + path))
    }, [])
    return (
        <div className="slide-card">
            <Tabs activeKey={defaultActiveKey} onSelect={(key) => {
                clickTab(key)
            }}>
                <Tab eventKey="card-shop" title="Card Shop">
                    {
                        shopContract && account && defaultActiveKey === 'card-shop' ?
                            <Carousels
                                shopContract={shopContract}
                                heroContract={heroContract}
                                lgdHeroContract={lgdHeroContract}
                                lgdTokenContract={lgdTokenContract}
                                web3={web3}
                            />
                            :
                            <ButtonWrap>
                                <Button
                                    text="Unlock Wallet"
                                    account={account}
                                />
                            </ButtonWrap>
                    }
                </Tab>
                <Tab eventKey="my-cards" title="My Cards">
                    {
                        shopContract && account && defaultActiveKey === 'my-cards' ?
                            <SearchCard
                                shopContract={shopContract}
                                heroContract={heroContract}
                                lgdHeroContract={lgdHeroContract}
                                web3={web3}
                            />
                            :
                            <ButtonWrap>
                                <Button
                                    text="Unlock Wallet"
                                    account={account}
                                />
                            </ButtonWrap>
                    }
                </Tab>
            </Tabs>
        </div>
    )
}
const ButtonWrap = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default SlideCard
