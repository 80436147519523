import React from 'react'
import styled from 'styled-components'

import {Container} from 'reactstrap'
import Background from '../../../assets/img/background/homepage_expand_alliance_section.png'
import homepage_brand_border from '../../../assets/img/background/homepage_brand_border.png'
import Button from '../../../components/Button'

import mcx from '../../../assets/img/logo/mcx.png'
import mdex from '../../../assets/img/logo/mdex.png'
import hotbit from '../../../assets/img/logo/hotbit.png'
import ztglobal from '../../../assets/img/logo/ztglobal.png'
import dao_marker from '../../../assets/img/logo/dao_marker.png'
import newsbtc from '../../../assets/img/logo/newsbtc.png'
import btcm from '../../../assets/img/logo/btcm.png'
import cryp from '../../../assets/img/logo/cryp.png'
import the_cointele from '../../../assets/img/logo/the_cointele.png'
import coin from '../../../assets/img/logo/coin.png'

interface HomePageSection1Props {
}

const HomePageSection1: React.FC<HomePageSection1Props> = () => {
  return (
    <Container className="themed-container brand" fluid="xl" style={{
      background: `url(${Background})`,
      marginTop: -1
    }}>
      <Container className="themed-container" fluid="lg">
        <div></div>
        <StyledWrap className="brand-section">
          <StyledWrapButton className="brand-button"><Button text='Partners' size='xl'/></StyledWrapButton>
          <StyledWrapBrand>
            <div><img src={btcm}/></div>
            <div><img src={mdex}/></div>
            <div><img src={hotbit}/></div>
            <div><img src={ztglobal}/></div>
            <div><img src={dao_marker}/></div>
            <div><img src={newsbtc}/></div>
            <div><img src={mcx}/></div>
            <div><img src={cryp}/></div>
            <div><img src={the_cointele}/></div>
            <div><img src={coin}/></div>
          </StyledWrapBrand>
        </StyledWrap>

      </Container>
    </Container>
  )
}

const StyledWrapButton = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding-top: 57px;
  margin: 0 auto;
  width: 44%;
  justify-content: space-around;
`

const StyledWrap = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 1112px;
  background-image: url(${homepage_brand_border});
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  margin: 0 auto;
  margin-top: 31px;
  max-width: 100%;
  background-size: 100% 100%;
  padding-bottom: 50px;
`

const StyledWrapBrand = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 55px;
  margin-bottom: 50px;
  justify-content: space-between;
  width: 90%;
  flex-wrap: wrap;
  
  & > div {
    width: 18%;
    margin: 20px 0;
  }
  
  & > div > img {
    height: 36px;
  }
  
  @media (max-width: 576px) {
    & > div {
       width: 100%;
       margin: 20px 0;
       text-align: center;
    }
  }
`

export default HomePageSection1
