import React from 'react'
import Page from '../../components/Page'
import TopSection from './components/TopSection'
import FooterSection from './components/FooterSection'
import CardSection from './components/CardSection'
import BuildHeroSection from './components/BuildHeroSection'
import BannerSection from './components/BannerSection'
import BattleSection from './components/BattleSection'
import BrandSection from './components/BrandSection'
import './components/Home.css'
// import {setupNetwork} from "../../utils/wallet";

const Home: React.FC = () => {
    // setupNetwork()
    return (
        <Page>
            <TopSection/>
            <BannerSection/>
            <CardSection/>
            <BuildHeroSection/>
            <BattleSection/>
            <BrandSection/>
            <FooterSection/>
        </Page>
    )
}

export default Home
