import React, {useEffect} from 'react'
import Button from '../../../components/Button'
import calabashNormalHero from '../../../assets/img/card/calabashNormalHero.png'
import './SearchCard.css'
import FilterCard from './FilterCard'
import {Modal} from 'react-bootstrap'
import {Contract} from "web3-eth-contract";
import {useWallet} from "use-wallet";
import {heroArmory, lgdHero, shop} from "../../../sushi/utils";
import {CardQuality} from "../../../utils/card-quality";
import {getAvatarUrl, useFetchState} from "../../../utils";

interface SearchCardProps {
    heroContract: Contract
    shopContract: Contract
    lgdHeroContract: Contract
    web3: any
}

const defaultCard: any = {
    name: '',
    quality: '',
    fightRate: '',
    cardShowRate: '',
    fight: '',
    prop: '',
    tokenId: ''
}
const SearchCard: React.FC<SearchCardProps> = ({shopContract, heroContract, lgdHeroContract, web3}) => {
    const {account} = useWallet()
    const [fullRows, setFullRows] = useFetchState([])
    const [rows, setRows] = useFetchState([])
    const [card, setCard] = useFetchState(defaultCard)
    const [tokenId, setTokenId] = useFetchState('')
    const [searchValue, setSearchValue] = useFetchState('')
    const [showRenameFunc, setShowRenameFunc] = useFetchState(false)
    const [showTransferFunc, setShowTransferFunc] = useFetchState(false)
    const [renameValue, setRenameValue] = useFetchState('');
    const [isRenameSuccess, setIsRenameSuccess] = useFetchState(false);
    const [isRenameError, setIsRenameError] = useFetchState(false)
    const [renameErrorMsg, setRenameErrorMsg] = useFetchState('The name already exist, please try again');
    const [isLoading, setIsLoading] = useFetchState(false);

    const [transferValue, settransferValue] = useFetchState('');
    const [isTranferSuccess, setIsTranferSuccess] = useFetchState(false);
    const [isTranferError, setIsTranferError] = useFetchState(false)
    const [tranferErrorMsg, setTranferErrorMsg] = useFetchState('Please enter the correct receiving address');

    const closeRename = () => setShowRenameFunc(false)
    const showRename = () => setShowRenameFunc(true)
    const closeTransfer = () => setShowTransferFunc(false)
    const showTransfer = () => setShowTransferFunc(true)

    function handleShowRename(id: string) {
        let card = rows.find((item: any) => item.tokenId === id) || {}
        setTokenId(id)
        setCard(card)
        setRenameValue(card.name)
        setIsRenameSuccess(false);
        showRename();
    }

    function handleShowTransfer(id: string) {
        let card = rows.find((item: any) => item.tokenId === id) || {}
        setTokenId(id)
        setCard(card)
        settransferValue('');
        setIsTranferSuccess(false);
        showTransfer();
    }

    function onKeydownSearch(e: any) {
        if (e.keyCode === 13) {
            search()
        }
    }

    function search() {
        let list = [...fullRows].filter((item: any) => {
            if (searchValue === '') {
                return true
            }
            try {
                return new RegExp(searchValue, 'i').test(item.name)
            } catch (e) {
                return item.name.includes(searchValue)
            }
        })
        setRows(list)
    }

    const handleChangeName = (e: any) => {
        const value = e.currentTarget.value;
        if (value === 'iron') {
            setIsRenameError(true);
            setRenameErrorMsg('The name already exist, please try again')
        } else if (value.length >= 12) {
            setIsRenameError(true);
            setRenameErrorMsg('Please enter 1-12 character');
        } else {
            setIsRenameError(false);
        }
        setRenameValue(value);
    }

    const handleChangeAddress = (e: any) => {
        const value = e.currentTarget.value;
        if (!isValidReceivingAddress(value)) {
            setIsTranferError(true);
        } else {
            setIsTranferError(false);
        }
        settransferValue(value);
    }

    const isValidReceivingAddress = (address: any) => {
        if (!address || !/^(0x)?[0-9a-fA-F]{40}$/.test(address)) {
            return false;
        }
        return true;
    }

    const handleSubmitRename = () => {
        setIsLoading(true);
        shop.changeHeroName(shopContract, tokenId, renameValue, account).then(res => {
            let newCard = res.events.ChangeName.returnValues
            setIsLoading(false);
            setIsRenameSuccess(true)
            if (tokenId === newCard.tokenId) {
                setCard({
                    ...card,
                    name: newCard.name
                })
            }

            let newList = rows.map((item: any) => {
                if (item.tokenId === newCard.tokenId) {
                    item.name = newCard.name
                }
                return item
            })
            setRows(newList)
            setFullRows(newList)
            setTimeout(() => {
                closeRename()
            }, 2000)
        }).catch(() => {
            setIsLoading(false);
            setIsRenameError(false)
        })
    }

    const handleSubmitReceiver = () => {
        setIsLoading(true);
        lgdHero.safeTransferFrom(lgdHeroContract, transferValue, tokenId, account).then(res => {
            setIsLoading(false);
            setIsTranferSuccess(true)
            setTranferErrorMsg('Successful transaction')
            let newList = [...rows]
            let index = newList.findIndex(item => item.tokenId === tokenId)
            if (index > -1) {
                newList.splice(index, 1)
            }
            setCard(defaultCard)
            setTokenId('')
            setRows(newList)
            setFullRows(newList)
            setTimeout(() => {
                closeTransfer()
            }, 1000)
        }).catch((e) => {
            setIsLoading(false);
            setIsTranferError(true)
            setTranferErrorMsg(e?.message)
        })
    }

    useEffect(() => {
        if (heroContract && rows.length === 0) {
            heroArmory.getHeros(heroContract, account).then(res => {
                const all = res.map((item: any, index: number) => {
                    let q = CardQuality[item.quality]
                    return {
                        name: item.name,
                        quality: q.name,
                        fightRate: q.fight,
                        cardShowRate: q.rate,
                        fight: item.fight,
                        prop: item.prop,
                        tokenId: item.tokenId,
                        imgUrl: getAvatarUrl(item),
                    }
                })
                console.log('mycard', all)
                setRows(all)
                setFullRows(all)
            })
        }
    }, [heroContract, web3, account])

    return (
        <div className="search__card">
            <div className="search__card--container">
                <FilterCard
                    onChange={(e: any) => {
                        setSearchValue(e.target.value)
                    }}
                    onKeyDown={(e: any) => {
                        onKeydownSearch(e)
                    }}
                    onClick={search}
                    value={searchValue}
                />
                <p className="result">Found {rows.length} cards</p>
                <div className="list-card-result">
                    {
                        rows.map((card: any, index: number) => <div className="card-result" key={index}>
                            <img src={card.imgUrl} alt=""/>
                            <div className="infor-card">
                                <p>Name: {card.name}</p>
                                <p>战斗力加成: {card.fightRate}%</p>
                                <p>卡片出现概率: {card.cardShowRate}%</p>
                                <p>Fight: {card.fight}</p>
                                <div className="rename">
                                    <Button text="Rename" size="sm" onClick={() => handleShowRename(card.tokenId)}/>
                                </div>
                                <div className="transfer">
                                    <Button text="Transfer" size="sm"
                                            onClick={() => handleShowTransfer(card.tokenId)}/>
                                </div>
                            </div>
                        </div>)
                    }

                    <Modal
                        show={showRenameFunc}
                        onHide={closeRename}
                        className="rename-box"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Rename</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                isRenameSuccess ?
                                    <p className="success-text"> Successfully &#10004;</p> : null
                            }
                            <div className="group-info">
                                <img src={calabashNormalHero}/>
                                <div className="information">
                                    <p>Name: {card.name}</p>
                                    <p>战斗力加成: {card.fightRate}%</p>
                                    <p>卡片出现概率: {card.cardShowRate}%</p>
                                    <p>Fight: {card.fight}</p>
                                </div>
                            </div>
                            {
                                !isRenameSuccess ?
                                    <div className="form-group">
                                        <div className="left">
                                            <label>Enter a name</label>
                                            <div className="group-input">
                                                <div className={isRenameError ? "bg-red" : "bg-black-name"}>
                                                    <input placeholder="Please enter 1-12 character" type="text"
                                                           value={renameValue} className="rename-input"
                                                           onChange={(e) => handleChangeName(e)}/>
                                                </div>
                                                {
                                                    isRenameError ?
                                                        <span className="error">
                        {renameErrorMsg}
                      </span> : null
                                                }

                                            </div>
                                        </div>
                                        <div className="right">
                                            <Button disabled={isRenameError || isLoading}
                                                    text={isLoading ? "Confirm..." : "Confirm"} onClick={() => {
                                                handleSubmitRename()
                                            }} size="sm"/>
                                        </div>
                                    </div> : null
                            }
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={showTransferFunc}
                        onHide={closeTransfer}
                        className="rename-box"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Transfer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                isTranferSuccess ?
                                    <p className="success-text"> Successfully &#10004;</p> : null
                            }
                            <div className="group-info">
                                <img src={calabashNormalHero}/>
                                <div className="information">
                                    <p>Name: {card.name}</p>
                                    <p>战斗力加成: {card.fightRate}%</p>
                                    <p>卡片出现概率: {card.cardShowRate}%</p>
                                    <p>Fight: {card.fight}</p>
                                </div>
                            </div>
                            {
                                !isTranferSuccess ?
                                    <div className="form-group">
                                        <div className="left">
                                            <label>Receiver</label>
                                            <div className="group-input">
                                                <div className={isTranferError ? "bg-red" : "bg-black-name"}>
                                                    <input placeholder="Please enter receiving address" type="text"
                                                           className="rename-input"
                                                           value={transferValue}
                                                           onChange={(e) => handleChangeAddress(e)}/>
                                                </div>
                                                {
                                                    isTranferError ?
                                                        <span className="error">
                            {tranferErrorMsg}
                          </span> : null
                                                }

                                            </div>
                                        </div>
                                        <div className="right">
                                            <Button disabled={isTranferError || !transferValue || isLoading}
                                                    text={isLoading ? "Transfer..." : "Transfer"}
                                                    onClick={() => handleSubmitReceiver()} size="sm"/>
                                        </div>
                                    </div> : null
                            }
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default SearchCard
