import React from 'react'
import styled from 'styled-components'

import {Container} from 'reactstrap'
import Background from '../../../assets/img/background/home_page_card_section.png'
import bookBackGround from '../../../assets/img/background/homepage_banner_section_book.png'
import calabashHeroBannerCard from '../../../assets/img/card/calabashHeroBannerCard.png'
import spidermanHero from '../../../assets/img/card/spidermanHero.png'

interface HomePageSection1Props {}

const HomePageSection1: React.FC<HomePageSection1Props> = () => {
  return (
    <Container
      className="themed-container card-section"
      fluid="xl"
      style={{
        background: `url(${Background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Container className="themed-container card-container" fluid="lg">
        <StyledWrap className="card-title">
          <StyledTitle className="heading">WELCOME TO LEGENDSHOT</StyledTitle>
        </StyledWrap>
        <StyledWrapCard className="card-images">
          <StyledCard className="card-images-item" url={calabashHeroBannerCard}>
            <StyledLink className="card-images-item-link">Product Introduce</StyledLink>
          </StyledCard>
          <StyledCard className="card-images-item" url={spidermanHero}>
            <StyledLink className="card-images-item-link">User Guide</StyledLink>
          </StyledCard>
        </StyledWrapCard>
      </Container>
    </Container>
  )
}

const StyledWrap = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-top: 248px;
  margin-top: 121px;
  background-image: url(${bookBackGround});
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
`

const StyledWrapCard = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin-top: 121px;
  justify-content: space-evenly;
`

const StyledCard = styled.div<{ url: string }>`
  align-items: center;
  box-sizing: border-box;
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  position: relative;
  width: 307px;
  height: 283px;
  background-size: 100% 100%;
`

const StyledTitle = styled.h1`
  color: ${(props) => props.theme.color.white};
  font-size: 36px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 36%;
  left: 36%;
  width: 360px;
  text-align: center;
  --stroke-color: #560202;
  --stroke-width: 4px;
  color: white;
  text-shadow: var(--stroke-width) 0 0 var(--stroke-color), calc(var(--stroke-width) * -1) 0 0 var(--stroke-color), 0 var(--stroke-width) 0 var(--stroke-color), 0 calc(var(--stroke-width) * -1) 0 var(--stroke-color);
`
const StyledLink = styled.a`
  color: ${(props) => props.theme.color.white};
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 9%;
  left: 0;
  width: 100%;
  text-align: center;
`

export default HomePageSection1
