import React from 'react'
import styled from 'styled-components'
import useModal from "../../hooks/useModal";
import WalletProviderModal from "../WalletProviderModal";

interface ButtonProps {
    disabled?:boolean
    children?: React.ReactNode,
    onClick?: () => void,
    text?: string,
    account?: string
    className?: string
}

const AccountStyledButton: React.FC<ButtonProps> = (
    {
        disabled,
        children,
        onClick,
        text,
        account,
        className
    }
) => {

    const [onPresentWalletProviderModal] = useModal(<WalletProviderModal/>)

    return (
        <StyledButton
            disabled={disabled}
            className={className}
            onClick={account === null ? onPresentWalletProviderModal : onClick}
        >
            {children}
            {text}
        </StyledButton>
    )
}

const StyledButton = styled.button`
`

export default AccountStyledButton
