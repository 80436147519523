import React, {useState} from 'react'
import styled from 'styled-components'

import {Carousel, CarouselControl, CarouselItem, Container} from 'reactstrap'
import Background from '../../../assets/img/background/homepage_banner_section.png'
import firstBanner from '../../../assets/img/banner/first.png'
import './BannerSection.css'

interface HomePageSection1Props {}

const items = [
  {
    src: firstBanner,
    altText: 'Slide 1',
    caption: 'Slide 1'
  },
  {
    src: firstBanner,
    altText: 'Slide 1',
    caption: 'Slide 1'
  },
  {
    src: firstBanner,
    altText: 'Slide 1',
    caption: 'Slide 1'
  }
];

const HomePageSection1: React.FC<HomePageSection1Props> = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex: any) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item,index) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={index}
      >
        <img src={item.src} alt={item.altText} />
      </CarouselItem>
    );
  });

  return (
    <Container className="themed-container banner-section" fluid="xl" style={{
      background: `url(${Background})`,
      marginTop: -1,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }}>
      <Container className="themed-container" fluid="lg">
        <StyledWrap className="banner-container">
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
          >
            {slides}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
          </Carousel>
        </StyledWrap>
        
      </Container>
    </Container>
  )
}

const StyledWrap = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => props.theme.spacing[6]}px;
  padding-top: ${(props) => props.theme.spacing[6]}px;
  margin: 0 auto;
`

export default HomePageSection1
