import React from 'react'
import styled from 'styled-components'

import {Container} from 'reactstrap'
import Background from '../../../assets/img/background/homepage_build_hero_section.png'
import calabashHero from '../../../assets/img/card/calabashHero.png'
import deappoolHero from '../../../assets/img/card/deappoolHero.png'
import calabashNormalHero from '../../../assets/img/card/calabashNormalHero.png'
import ironManHero from '../../../assets/img/card/ironManHero.png'
import Button from '../../../components/Button'
import {useHistory} from 'react-router'
import {generateLink} from "../../../utils";
import {useTranslation} from "react-i18next";

interface HomePageSection1Props {}

const HomePageSection1: React.FC<HomePageSection1Props> = () => {
  const history = useHistory();
  const {i18n} = useTranslation()
  return (
    <Container className="themed-container hero-section" fluid="xl" style={{
      background: `url(${Background})`,
      backgroundSize: 'cover'
    }}>
      <Container className="themed-container" fluid="lg">
        <StyledWrap className="hero-container">
          <StyledWrapCard className="hero-card">
            <StyledCard className="hero-card-item" url={ironManHero}></StyledCard>
            <StyledCard className="hero-card-item" url={calabashHero}></StyledCard>
            <StyledCard className="hero-card-item" url={deappoolHero}></StyledCard>
            <StyledCard className="hero-card-item" url={calabashNormalHero}></StyledCard>
          </StyledWrapCard>
          <StyledWrapText className="hero-text">
            <StyledTitle>Get your Hero Cards</StyledTitle>
            <StyledWrapButton>
              <Button onClick={() => {
                history.push(generateLink(i18n, '/cardshop'))
              }} text='Buy Card' size='md' />
              <Button text='Introduce' size='md' bgColor='blue' />
            </StyledWrapButton>
          </StyledWrapText>
        </StyledWrap>
      </Container>
    </Container>
  )
}

const StyledWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 166px;
  position: relative;
`

const StyledWrapCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 0 0 62%;
  max-width: 62%;
`

const StyledWrapText = styled.div`
  padding-top: 30px;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 0 0 38%;
  max-width: 38%;
`

const StyledCard = styled.div<{url: string}>`
  align-items: center;
  box-sizing: border-box;
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  position: relative;
  width: 197px;
  height: 251px;
`

const StyledTitle = styled.h1`
  color: ${(props) => props.theme.color.white};
  font-size: 36px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  width: 74%;
  --stroke-color: #560202;
  --stroke-width: 4px;
  color: white;
  text-shadow: var(--stroke-width) 0 0 var(--stroke-color), calc(var(--stroke-width) * -1) 0 0 var(--stroke-color), 0 var(--stroke-width) 0 var(--stroke-color), 0 calc(var(--stroke-width) * -1) 0 var(--stroke-color);
`
const StyledWrapButton = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin-top: 29px;
  width: 69%;
  justify-content: space-around;
`

export default HomePageSection1
